import { Fragment } from 'react';
import classes from '../styles/Projects.module.css'
import snapClass from "../styles/Index.module.css"
import { ArrayOfProjects } from '../Helpers/ArrayOfProjects';
import { motion } from "framer-motion"

function Projects() {
    const projects = ArrayOfProjects.map(project =>
        <div className="mb-8 flex flex-wrap lg:justify-center" key={project.cardId}>
            <motion.div
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 100 }}
                transition={{ duration: 1 }}
                className={`${classes['image-mod']} w-full lg:w-1/4`}>
                <a href={project.projectUrl} target="_blank" rel="noreferrer">
                    <img src={project.projectImage}
                        alt="project img"
                        width={150}
                        height={150}
                        className={`mb-6 rounded`} /></a>
            </motion.div>
            <motion.div
                whileInView={{ opacity: 1, y: 0 }}
                initial={{ opacity: 0, y: 100 }}
                transition={{ duration: 1 }}
                className="w-full max-w-xl lg:w-3/4">
                <div className={classes['title-and-github-btn']}>
                    <h6 className={`mb-2 text-yellow-300 font-farroBold text-left`}>{project.projectTitle}</h6>
                    {project.cardId === '3' || project.cardId === '4' || project.cardId === '5'
                        ? <a href={project.projectUrl} target="_blank" rel="noreferrer" className={`text-l font-farroRegular ${classes['view-project']}`}>No Github!</a>
                        : <a href={project.projectUrl} target="_blank" rel="noreferrer" className={`text-l font-farroRegular ${classes['view-project']}`}>View Github!</a>}
                </div>
                <p className={`${classes['description-mod']} mb-4 text-yellow-400 font-farroLight text-left`}>{project.projectDescription}</p>
            </motion.div>
        </div>
    )

    return (
        <Fragment>
            <section className={`${snapClass['snap-segment']}`}>
                <div className={classes.container}>
                    <div id={"projects"} className={`${classes['projects-subheader']}`}>
                        <motion.h1
                            whileInView={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: -100 }}
                            transition={{ duration: 0.5 }}
                        >Projects
                        </motion.h1>
                    </div>
                    {projects}
                </div>
            </section>
        </Fragment>
    );
}

export default Projects;