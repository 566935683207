import PokemonGameImage from '../images/pokemongame.jpg'
import OlympixProject from '../images/OlympiX.jpg'
import UcanProject from '../images/UCan.jpg'
import ExpenditureTracker from '../images/expendituretracker.png'
import TicketDispatcher from '../images/ticketdispatcher.png'
import PythonGameImage from '../images/pythonGame.png'
import PowerBiProject from '../images/power-bi-pizza-data-analysis.png'
import CSharpGame from "../images/cSharpGameDriver.PNG"
export const ArrayOfProjects = [
    {
        cardId: '1',
        projectTitle: 'Expenditure Tracker',
        projectImage: ExpenditureTracker,
        projectDescription: "A web app built to track your expenses based on data you've logged which includes location/address and more.",
        projectUrl: `https://github.com/mScrub/expenditure-tracker`
    },
    {
        cardId: '2',
        projectTitle: 'Ticket Dispatcher',
        projectImage:TicketDispatcher,
        projectDescription: "A ticketing system improved for our client in BCIT’s industry sponsored student projects.",
        projectUrl: `https://github.com/mScrub/ticket-dispatcher`
    },
    {
        cardId: '3',
        projectTitle: 'Microsoft Power BI Project',
        projectImage: PowerBiProject,
        projectDescription: "Pizza Sales Analysis/Report created through ETL process within a team setting of two.",
    },    {
        cardId: '4',
        projectTitle: 'C# Game (Delivery)',
        projectImage: CSharpGame,
        projectDescription: "A basic delivery driver game developed in C# to pick up and deliver packages to residences ",
    },
    {
        cardId: '5',
        projectTitle: 'Python Game',
        projectImage: PythonGameImage,
        projectDescription: "A text-based RPG in which the player decides to move in a 10x10 grid to encounter the environment or fight foes. ",
    },
    // {
    //     cardId: '6',
    //     projectTitle: 'OlympiX',
    //     projectImage: OlympixProject,
    //     projectDescription: "A hassle free live-stream mobile app for 2030 Vancouver Olympics for any age, especially the elderly demographic.",
    //     projectUrl: `https://github.com/mScrub/OlympiX-App`
    // },
    // {
    //     cardId: '7',
    //     projectTitle: 'UCAN',
    //     projectImage: UcanProject,
    //     projectDescription: "A mobile application for refugees from Ukraine to assist them in finding free resources from Canada.",
    //     projectUrl: `https://github.com/mScrub/UCAN-App`
    // },
    // {
    //     cardId: '8',
    //     projectTitle: 'Pokémon App',
    //     projectImage: PokemonGameImage,
    //     projectDescription: "Look-up page for Pokémon by various attributes, name, and shop for desired game cards.",
    //     projectUrl: `https://pokemon-app-heroku-deploy.herokuapp.com/`
    // },
]

