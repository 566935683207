import React, {Fragment} from 'react';
import classes from '../styles/Index.module.css'

// For each block of description contents about me!
const AboutMeCard = (props) => {
    return (
        <Fragment>
            <div className={classes['about-me-page']}>
                <h2>{props.title}</h2>
                <span><img src={props.images} alt={props}/></span>
                <p>{props.children}</p>
            </div>
        </Fragment>
        
    );
};

export default AboutMeCard