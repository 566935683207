import AboutMeBanner from './AboutMeBanner';
import Intro from './Intro' // top portion of website. 
import TopNavBar from './Header'
import Technologies from './Technologies';
import Projects from './Projects'
import Footer from './Footer'
import classes from '../styles/Home.module.css'

const Home = () => {
  return (
    <div className="App">
      <TopNavBar />
      <div className={`${classes['snap-container']}`}>
        <Intro />
        <AboutMeBanner />
        <Technologies />
        <Projects />
      </div>
      <Footer />
    </div>
  )
}

export default Home;