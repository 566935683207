import personality from '../images/personality.png'
import skills from '../images/obtainmoreskills.png'
import languages from '../images/jsbrowser.png'

const ABOUT_ME_LIST = [
    {
        cardId: '1',
        cardTitle: 'Personality',
        cardImage: personality,
        cardText: "No matter what projects I am working on, whether they are personal, academic, or even for clients, I always approach them from a growth mindset. It is also enjoyable for me to be in a state of solving problems since it is a sort of a puzzle-solving process. Due diligence, adaptability, and teamwork are my strengths."
    },
    {   
        cardId: '2',
        cardTitle: 'Skills',
        cardImage: skills,
        cardText: "Ability to acquire new skills quickly and effectively. Additionally, I like to approach problems in different ways. Having completed BCIT Computer Systems Technology, I have developed skills in MVC & OOP design principles, algorithms & data structures, and SQL queries."
    },
    {   
        cardId: '3',
        cardTitle: 'Languages',
        cardImage: languages,
        cardText: "I am proficient in a number of programming languages, including C#, Python, and Javascript, and continue to seek to master them as my career progresses."
    },
]

export default ABOUT_ME_LIST;