import React, { useEffect, useRef, Fragment } from 'react';
import { HashLink as LinkSpecificPart } from 'react-router-hash-link';
import classes from '../styles/Index.module.css';
import '../styles/Input.css';
import Selfie from '../images/SelfResizedR1.png';
import particles from '../assets/particles.json'
import 'particles.js/particles';
import { motion } from 'framer-motion';

const downContainer = (delay) => ({
    hidden: { y: -100, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 0.5, delay: delay }
    }
})


const Intro = () => {
    const toTopButtonRef = useRef(null);

    useEffect(() => {
        window.particlesJS('particles-js', particles);
        //** Maintain position upon resize */
        const handleResize = () => {
            const canvasEl = document.querySelector(".particles-js-canvas-el");
            if (window.innerWidth < 480) {
                const intervalId = setInterval(() => {
                    if (canvasEl) {
                        canvasEl.style.position = 'relative';
                        canvasEl.style.width = '100vw';
                        canvasEl.style.height = '100vh';
                        canvasEl.style.zIndex = '1';
                        clearInterval(intervalId);
                    }
                }, 100); // Check every 100 milliseconds
            } else {
                const intervalIdDesktop = setInterval(() => {
                    if (canvasEl) {
                        canvasEl.style.position = 'relative';
                        canvasEl.style.width = '100vw';
                        canvasEl.style.height = '100vh';
                        clearInterval(intervalIdDesktop);
                    }
                }, 100)
            }
        }

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize)
        };
    }, []);


    useEffect(() => {
        const handleScroll = () => {
            if (toTopButtonRef.current) {
                if (window.scrollY > 100) {
                    toTopButtonRef.current.classList.remove("hidden");
                } else {
                    toTopButtonRef.current.classList.add("hidden");
                }
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => { // on code cleanup
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    return (
        <Fragment>
            <section className={`${classes['snap-segment']}`}>
                <div className={classes['intro-container']}>
                    <div id="particles-js"></div>
                    <div className={classes['intro-content']}>
                        <div className={`${classes['inner-container']} flex flex-col align-items center mb-16`}>
                            <motion.a
                                variants={downContainer(0.1)}
                                initial="hidden"
                                animate="visible"
                                className={`mt-5 text-5xl font-farroMedium text-yellow-text-hlight`}>Felix Lieu</motion.a>
                            <motion.img
                                initial={{ y: 100, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ duration: 1, delay: 0.75 }}
                                className={classes['image-element']} src={Selfie} alt="Self" />
                            <div className={classes['introduction-description']}>
                                <motion.h1
                                    variants={downContainer(0.35)}
                                    initial="hidden"
                                    animate="visible"
                                >Welcome to my web portfolio!
                                </motion.h1>
                                <motion.p
                                    initial={{ y: 100, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 1, delay: 0.75 }}
                                >I am a Software Developer dedicated to applying my technical expertise to meet your business needs. </motion.p>
                            </div>
                            <div className="mt-2 mb-50 flex items-center justify-center gap-x-6">
                                <motion.a
                                    variants={downContainer(0.5)}
                                    initial="hidden"
                                    animate="visible"
                                >
                                    <LinkSpecificPart
                                        smooth to="/#about"
                                        className="rounded-md bg-yellow-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-white-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        About Me
                                    </LinkSpecificPart>
                                </motion.a>
                            </div>
                        </div>
                    </div>
                </div>
                <button id="to-top-button" ref={toTopButtonRef} onClick={goToTop} title="Go To Top"
                    className={`${classes['to-top-btn']} hidden fixed z-5 bottom-10 right-10 p-4 border-0 w-14 h-14 rounded-full shadow-md bg-yellow-400 hover:bg-yellow-500 text-lg font-semibold transition-colors duration-300`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" className="w-6 h-6">
                        <path d="M12 4l8 8h-6v8h-4v-8H4l8-8z" />
                    </svg>
                    <span className="sr-only">Go to top</span>
                </button>
            </section>
        </Fragment>
    );
}

export default Intro;
