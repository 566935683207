import React, { useState, useEffect, Fragment } from 'react';
import { HashLink as LinkSpecificPart } from 'react-router-hash-link';
import { Link } from 'react-router-dom'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import flIcon from '../images/fl-icon.png'

const TopNavBar = () => {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const navigation = [
    { name: 'About', link: '/#about' },
    { name: 'Skills', link: '/#technologies' },
    { name: 'Projects', link: '/#projects'}
  ]

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true)

  const handleScroll = () => {
    const currentScrollPos = window.scrollY

    if (currentScrollPos > prevScrollPos) {
      setVisible(false)
    } else {
      setVisible(true)
    }

    setPrevScrollPos(currentScrollPos)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <Fragment>
      {/* fixed position causes header content to overlap with the Intro section */}
        <header className={`${visible ? 'top-0' : 'fade-in-section'} ${'z-3 bg-transparent mb-20'}`}>
          <nav className={`${'flex items-center justify-between p-6 lg:px-8'}`} aria-label="Navigation Menu">
            <div className="flex lg:flex-1">
              <a className="text-2xl font-farroBold hover:animate-pulse hover:text-yellow-text-hlight leading-6 text-white-700">
                <Link key={navigation[0].name} to={navigation[0].link} />
                <img src={flIcon} alt="initials" style={{borderRadius: '2rem'}} />
              </a>
            </div>
            {/* Hamburger Menu */}
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white-700"
                onClick={() => setMobileMenuOpen(true)}>
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <LinkSpecificPart key={item.name} smooth to={item.link} className="text-xl font-farroMedium hover:text-yellow-text-hlight leading-6 text-white-700">
                  {item.name}
                </LinkSpecificPart>
              ))}
            </div>
            <div className="hidden lg:flex lg:flex-1 lg:justify-end"></div>
          </nav>

          <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-50" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full h-[33%] rounded-xl overflow-y-auto bg-custom-navy2 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <img src={flIcon} alt="Blob img" />
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-white-700"
                  onClick={() => setMobileMenuOpen(false)}>
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-white-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <LinkSpecificPart
                        key={item.name}
                        to={item.link}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white-900 hover:bg-gray-50">
                        {item.name}
                      </LinkSpecificPart>
                    ))}
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </header>
    </Fragment>
  )
}


export default TopNavBar;
