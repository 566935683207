import React from 'react';
import classes from '../styles/Index.module.css'; 
import AboutMeCard from './AboutMeCard';
import ABOUT_ME_LIST from '../Helpers/AboutMeConstList';
import { motion } from "framer-motion"
const AboutMeBanner = () => {
    const mappedJSXObjects = ABOUT_ME_LIST.map(aboutMeItem =>
        <AboutMeCard key={aboutMeItem.cardId} title={aboutMeItem.cardTitle} images={aboutMeItem.cardImage}>
            {aboutMeItem.cardText}
        </AboutMeCard>
    )
    return (
            <section className={classes['snap-segment']}>
                <div id={'about'} className={classes['spacing-for-about-section']}>
                    <div className={classes['about-me-section']}>
                        <motion.h1
                            whileInView={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: -100 }}
                            transition={{ duration: 0.5 }}>
                            About Me!
                        </motion.h1>
                        <div className={`${classes['stat-container']}`}>
                            <div className={`${classes['exp-container']}`}>
                                <motion.span className={`${classes['span-title']}`}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    initial={{ opacity: 0, y: -100 }}
                                    transition={{ duration: 0.5 }}>
                                    02+
                                </motion.span>
                                <motion.span
                                    whileInView={{ opacity: 1, y: 0 }}
                                    initial={{ opacity: 0, y: -100 }}
                                    transition={{ duration: 0.5 }}>
                                    Years
                                    <br />
                                    of experience
                                </motion.span>
                            </div>
                            <div className={`${classes['project-exp-container']}`}>
                                <motion.span className={`${classes['span-title']}`}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    initial={{ opacity: 0, y: -100 }}
                                    transition={{ duration: 0.5 }}>
                                    05+
                                </motion.span>
                                <motion.span
                                    whileInView={{ opacity: 1, y: 0 }}
                                    initial={{ opacity: 0, y: -100 }}
                                    transition={{ duration: 0.5 }}>
                                    Completed
                                    <br />
                                    projects
                                </motion.span>
                            </div>
                        </div>
                        <div className={classes.grid}>
                            {mappedJSXObjects}
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default AboutMeBanner;