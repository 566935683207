import React, { Fragment } from 'react';
import classes from "../styles/Technologies.module.css"
import snapScrollClass from "../styles/Index.module.css"
import StackIcon from "tech-stack-icons";
import { motion } from 'framer-motion';

const Technologies = () => {
    return (
        <Fragment>
            <section className={snapScrollClass['snap-segment']}>
                <div className={classes['skills-and-technologies']}>
                    <div id={'technologies'} className={classes['technologies-section']}>
                        <motion.h1
                            whileInView={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: -100 }}
                            transition={{ duration: 0.5 }}
                        >Technologies that I know</motion.h1>
                        <div className={classes['icon-grid-container']}>
                            <StackIcon className={`${classes['icon-bg']} ${classes['icon-size']}`} name="csharp" />
                            <StackIcon className={classes['icon-size']} name="python" />
                            <StackIcon className={classes['icon-size']} name="typescript" />
                            <StackIcon className={classes['icon-size']} name="js" />
                            <StackIcon className={classes['icon-size']} name="angular" />

                            <StackIcon className={classes['icon-size']} name="reactjs" />
                            <StackIcon className={classes['icon-size']} name="redux" />
                            <StackIcon className={classes['icon-size']} name="mysql" />
                            <StackIcon className={classes['icon-size']} name="postgresql" />
                            <StackIcon className={classes['icon-size']} name="postman" />

                            <StackIcon className={classes['icon-size']} name="mongodb" />
                            <StackIcon className={classes['icon-size']} name="firebase" />
                            <StackIcon className={classes['icon-size']} name="nodejs" />
                            <StackIcon className={classes['icon-size']} name="docker" />
                            <StackIcon className={classes['icon-size']} name="azure" />

                            <StackIcon className={classes['icon-size']} name="bootstrap5" />
                            <StackIcon className={classes['icon-size']} name="bash" />
                            <StackIcon className={classes['icon-size']} name="java" />
                            <StackIcon className={classes['icon-size']} name="html5" />
                            <StackIcon className={classes['icon-size']} name="css3" />
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Technologies;